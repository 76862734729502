import { type KycStatus, Profile } from '@/shared/types';

import { useProfileContext } from '../providers';

export function useKycStatus(profileFromArgs?: Profile) {
  const { profile } = useProfileContext();

  const { currentKyc } = profile ?? profileFromArgs ?? {};

  const checkKycStatus = (...status: KycStatus[]): boolean => {
    return !!currentKyc && status.includes(currentKyc?.status);
  };

  return { status: currentKyc?.status, checkKycStatus };
}
